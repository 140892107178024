'use client'

import { useState, useEffect } from 'react'

import { getLocalStorageItem, setLocalStorageItem } from 'libs/utils/localStorage'
import useSession from 'hooks/useSession'

import StickyBanner from '../StickyBanner'

const DismissibleStickyBanner = () => {
  const { screen } = useSession()
  const [isDismissed, setIsDismissed] = useState(false)

  const IS_SHIPPING_FEES_APPLIED_INFO_BANNER_DISMISSED = `is_shipping_fees_applied_info_banner_dismissed_${screen}`

  useEffect(() => {
    const dismissed = getLocalStorageItem(IS_SHIPPING_FEES_APPLIED_INFO_BANNER_DISMISSED) === 'true'
    setIsDismissed(dismissed)
  }, [IS_SHIPPING_FEES_APPLIED_INFO_BANNER_DISMISSED])

  const handleBannerDismiss = () => {
    setIsDismissed(true)
    setLocalStorageItem(IS_SHIPPING_FEES_APPLIED_INFO_BANNER_DISMISSED, 'true')
  }

  if (isDismissed) {
    return null
  }

  return <StickyBanner onClose={handleBannerDismiss} />
}

export default DismissibleStickyBanner
