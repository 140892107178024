import { useMemo } from 'react'

import useLocation from 'hooks/useLocation'
import useBreakpoint from 'hooks/useBreakpoint'
import useFeatureSwitch from 'hooks/useFeatureSwitch'

import { getTabName } from '../utils/tabs'
import { TABS } from '../constants'
import { TabName } from '../types'

const useTabs = () => {
  const shouldShowTabs = useBreakpoint().portables

  const isElectronicsTabEnabled = useFeatureSwitch('web_homepage_electronics_tab')

  const tabs = useMemo(() => {
    const enabledTabs: Array<(typeof TABS)[TabName]> = [TABS.all, TABS.designer]

    if (isElectronicsTabEnabled) enabledTabs.push(TABS.electronics)

    return enabledTabs
  }, [isElectronicsTabEnabled])

  const location = useLocation()
  const currentTab = getTabName(location.urlParams.tab)

  const navigateToTab = (tab: string) => location.pushHistoryState(`/?tab=${tab}`)

  return {
    navigateToTab,
    shouldShowTabs,
    currentTab: TABS[currentTab],
    tabs,
  }
}

export default useTabs
